import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Button from '../components/Button';
import Text from '../components/Text';

const Notice = ({ onButtonClick }) => {
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('page_third', { referral: 'postpay_sendbird' });
  }, []);

  const reservationHandler = () => {
    amplitude.getInstance().logEvent('click_reservation', { referral: 'postpay_sendbird' });
    window.open('https://tarotsister.channel.io/support-bots/49639', '_self');
  };

  return (
    <div style={{ width: '100%' }}>
      <Text style={{ width: '100%', marginTop: '1.6rem' }}>지금 통화가 가능하신가요?</Text>
      <SubText>
        선생님들 시간 관계상{' '}
        <span
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '1.7rem',
            lineHeight: '180%',
            color: '#E1354C',
          }}>
          지금 통화 가능하신 분<br />
        </span>
        을 먼저 도와드리고 예약하시는 분은 상담 순위가 다소 밀릴 수 있습니다.
      </SubText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          position: 'fixed',
          padding: '0 2rem',
          bottom: '1.6rem',
          left: 0,
          right: 0,
        }}>
        <Button
          width="39%"
          backgroundColor="#fff"
          text="아니오"
          color="#222529"
          border="1px solid black"
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '2rem',
            textAlign: 'center',
          }}
          onClickHandler={reservationHandler}
        />
        <Button
          width="59%"
          backgroundColor="#222529"
          text="통화 가능합니다"
          color="#fff"
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '2rem',
            textAlign: 'center',
          }}
          onClickHandler={onButtonClick}
        />
      </div>
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 180%;
  color: #2e1717;
  margin-top: 1.6rem;
`;
export default Notice;
