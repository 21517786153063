import {getUrlParams} from './facebookpixel';

export const fetchClientId= async _ =>{
    const clientId = await new Promise(resolve => {
        gtag('get', 'G-LN151GQ6W7', 'client_id', resolve)
      });
    return clientId;
}

export const setUserId=phone=>{
    gtag('config', 'G-LN151GQ6W7', {
        'user_id': phone
      });
}


export const fetchUtmMap=_=>{

    const utmSource = getUrlParams('utm_source');
    const utmCampaign = getUrlParams('utm_campaign');
    const utmContent = getUrlParams('utm_content');
    const utmMedium = getUrlParams('utm_medium');
    const gclid = getUrlParams('gclid');

    try{
        return {
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            utm_medium: utmMedium,
            gclid,
        };
    }catch(e){
        return {
            utm_source: "",
            utm_campaign: "",
            utm_content: "",
            utm_medium: "",
            gclid: ""
        }

    }


}
