import styled from 'styled-components';
import './loadAmplitude';
import { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import Header from './components/Header';
import Button from './components/Button';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import Notice from './pages/Notice';
import InputPhonePage from './pages/InputPhonePage';
import Ready from './pages/Ready';
import fetchAdequatePixel from './utils/facebookpixel';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

function App() {
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'postpay_sendbird' });
    fbq('track', 'PageView');
    fbq('trackSingle', fetchAdequatePixel(), 'PageView');
  }, []);
  const [pageNum, setPageNum] = useState(0);
  const buttonClickHandler = () => {
    setPageNum(prev => prev + 1);
  };

  const [inputWorry, setInputWorry] = useState('');
  const [inputPhone, setInputPhone] = useState('');

  const inputPhoneChangeHandler = event => {
    setInputPhone(prev => event.target.value);
  };

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  const readyStateHandler = phoneNum => {
    amplitude.getInstance().logEvent('register', { referral: 'postpay_sendbird' });
    fbq('trackSingle', fetchAdequatePixel(), 'CompleteRegistration');
    setTimeout(() => {
      window.open(`https://call.tarotsister.co/?phone=${phoneNum}`, '_self');
    }, 3000);
  };

  return (
    <>
      <Header />
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} />

        {pageNum === 0 && (
          <img
            src={'https://tnndonnqnixjfqlzqhxv.supabase.co/storage/v1/object/public/public/landingpage/painful.png'}
            width="100%"
            style={{ maxWidth: '37.2rem', marginTop: '-5rem', marginBottom: '5rem' }}
            alt="메인"
          />
        )}
        {pageNum === 1 && (
          <InputWorryPage value={inputWorry} onChange={inputWorryChangeHandler} onButtonClick={buttonClickHandler} />
        )}

        {pageNum === 0 && (
          <Button
            width="90%"
            backgroundColor="#E1354C"
            text="고민 입력"
            color="#fff"
            style={{
              position: 'fixed',
              bottom: '0.5rem',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '2rem',
              textAlign: 'center',
              marginTop: '10rem',
              marginBottom: '2.5rem',
            }}
            onClickHandler={buttonClickHandler}
          />
        )}

        {pageNum === 2 && <Notice onButtonClick={buttonClickHandler} />}
        {pageNum === 3 && (
          <InputPhonePage
            ready={readyStateHandler}
            value={inputPhone}
            onChange={inputPhoneChangeHandler}
            worry={inputWorry}
          />
        )}
        {pageNum === 4 && <Ready />}
      </div>
    </>
  );
}

export default App;
