
export const getUrlParams = keyword=>{
    const hashes = window.location.search.slice(window.location.search.indexOf('?') + 1).split('&')
    const params = {}
    hashes.forEach(hash => {
        const [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val).replace('+', ' ');
    })
    return Object.keys(params).includes(keyword) ?params[keyword]:'';
}

export const getFbp = ()=>{
    const result = /_fbp=(fb\.[1|0]\.\d+\.\d+)/.exec(window.document.cookie);
    if (!(result && result[1])) {
      return '';
    }
    return result[1];
  }

const fetchAdequatePixel=()=>{
    const pixel =  getUrlParams('pixel');
    return pixel;
}


export default fetchAdequatePixel;
